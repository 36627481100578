<template>
    <ValidationObserver ref="observer_user" tag="form" v-slot="{ handleSubmit }" @submit.prevent>
        <div>
            <b-row align-v="end">
                <b-col cols="6">
                    <dmx-title backlink :bc-items="bcItems"></dmx-title>
                </b-col>
                <b-col cols="6" class="d-flex align-items-end justify-content-end pb-4">
                    <b-button variant="success" type="submit" @click="handleSubmit(registerUser)">
                        {{ $t('common_create') }}
                    </b-button>
                </b-col>
            </b-row>
            <div class="dmx-card mt-3">
                <b-row>
                    <b-col>
                        {{ $t('common_firstname') }}<small class="text-danger"><em>
                                *</em></small>
                        <ValidationProvider name="Firstname" rules="required" v-slot="v">
                            <b-form-input spellcheck="false" type="text" autocomplete="off"
                                v-model="user.firstName"></b-form-input>
                            <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                        {{ $t('common_lastname') }}<small class="text-danger"><em>
                                *</em></small>
                        <ValidationProvider name="Lastname" rules="required" v-slot="v">
                            <b-form-input spellcheck="false" type="text" autocomplete="off"
                                v-model="user.lastName"></b-form-input>
                            <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                        </ValidationProvider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        {{ $t('common_phone') }}
                        <b-form-input v-model="user.phone"></b-form-input>
                    </b-col>
                    <b-col>
                        {{ $t('common_email') }}<small class="text-danger"><em>
                                *</em></small>
                        <ValidationProvider name="Email" rules="required|email" v-slot="v">
                            <b-form-input spellcheck="false" type="text" autocomplete="off"
                                v-model="user.email"></b-form-input>
                            <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                        </ValidationProvider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        {{ $t('common_password') }}<small class="text-danger"><em>
                                *</em></small>
                        <ValidationProvider name="Password" rules="required|password:@Confirm" v-slot="v">
                            <b-form-input spellcheck="false" type="text" autocomplete="off"
                                v-model="user.password"></b-form-input>
                            <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                        {{ $t('common_confirm_password') }}<small class="text-danger"><em>
                                *</em></small>
                        <ValidationProvider name="Confirm" rules="required" v-slot="v">
                            <b-form-input spellcheck="false" type="text" autocomplete="off"
                                v-model="user.confirmPassword"></b-form-input>
                            <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
import accountService from '@/services/accountService';
import router from '@/router';
export default {
    name: 'editUser',
    props: { userId: Number },
    components: {
    },
    data: () => ({
        user: {
            passwordFormat: 1
        },
        avaiableRoles: [],
    }),
    methods: {
        registerUser() {
            this.$refs.observer_user.validate().then(success => {
                if (!success) {
                    return;
                } else 
                {
                    accountService.register(this.user).then(result => {
                        router.go(-1)
                    });
                }
            });
        }
    },
    mounted() {
        accountService.getAvailableUserRoles()
            .then((data) => {
                this.avaiableRoles = data;
            });
    },
    computed: {
        bcItems() {
            return [
                {
                    text: 'users',
                    href: '/users',
                },
                {
                    text: 'Register new user',
                    href: '/user/registerUser',
                    active: true,
                }
            ]
        },
    }
}
</script>